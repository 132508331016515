import axios from "axios";
import { RequestResponse } from "components/types";

import { ENV } from "../constants";

export type PreLoginUserTypeResponse = {
  sso: boolean;
  isPasswordlessLogin?: boolean;
};

export type OneTimeLoginUrlResponse = {
  successMessage: string;
  redirect?: boolean;
};

export type UserValidateBadAuthAttemptsResponse = {
  redirectUrl?: string;
};

const API = {
  PRELOGIN: "/api/login/prelogin",
  ONETIMELOGINURL: "/api/login/oneTimeLoginUrl",
  SEND_PLAIN_ACTIVATION_TOKEN_EMAIL: "/user/activation/token",
  RESEND_ACTIVATION_EMAIL: "/user/activation/resend",
  SELF_SERVICE_EMAIL: "/user/self-service/testEmail",
  VALIDATE_USER_BAD_AUTH_ATTEMPTS: "/user/badAuthAttempts/validate",
  SEND_PASSWORDLESS_WHILE_ERROR: "/user/badAuthAttempts/oneTimeLoginUrl"
};

const CONFIG = {
  baseURL: ENV.API_CES_AUTH,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json"
  },
  withCredentials: true
};

const authAxiosInstance = axios.create(CONFIG);

let source;

const getPreLoginUserType = async (
  email?: string
): Promise<PreLoginUserTypeResponse> => {
  const CancelToken = axios.CancelToken;
  if (source) {
    source.cancel("cancel");
  }
  source = CancelToken.source();

  const url = `${API.PRELOGIN}${email ? "?email=" + email : ""}`;

  const { data } = await authAxiosInstance.get<PreLoginUserTypeResponse>(url, {
    baseURL: ENV.API_CES_AUTH,
    cancelToken: source.token
  });

  return data;
};

const oneTimeLoginUrl = async (
  email: string,
  landingUrl?: string
): Promise<OneTimeLoginUrlResponse> => {
  const CancelToken = axios.CancelToken;
  if (source) {
    source.cancel("cancel");
  }
  source = CancelToken.source();

  const { data } = await authAxiosInstance.post<OneTimeLoginUrlResponse>(
    API.ONETIMELOGINURL,
    {
      email,
      landingUrl
    },
    {
      baseURL: ENV.API_CES_AUTH,
      cancelToken: source.token
    }
  );

  return data;
};

const sendActivationCode = async (email: string): Promise<void> =>
  authAxiosInstance.post(
    API.SEND_PLAIN_ACTIVATION_TOKEN_EMAIL,
    {
      email
    },
    {
      baseURL: ENV.API_CES_AUTH
    }
  );

const resendActivationEmail = async (email: string): Promise<void> =>
  authAxiosInstance.post(
    API.RESEND_ACTIVATION_EMAIL,
    {
      email
    },
    {
      baseURL: ENV.API_CES_AUTH
    }
  );

const sendSelfServiceEmail = async (email: string): Promise<void> =>
  authAxiosInstance.post(
    API.SELF_SERVICE_EMAIL,
    {
      email
    },
    {
      baseURL: ENV.API_CES_AUTH
    }
  );

const validateUserBadAuthAttempts = async (
  email: string,
  message?: string,
  landingUrl?: string
): Promise<UserValidateBadAuthAttemptsResponse> => {
  const { data } =
    await authAxiosInstance.post<UserValidateBadAuthAttemptsResponse>(
      API.VALIDATE_USER_BAD_AUTH_ATTEMPTS,
      {
        email,
        message,
        landingUrl
      },
      {
        baseURL: ENV.API_CES_AUTH
      }
    );

  return data;
};

const sendPasswordlessWhileError = async (
  email: string,
  landingUrl?: string
): Promise<RequestResponse> => {
  const { data } = await authAxiosInstance.post(
    API.SEND_PASSWORDLESS_WHILE_ERROR,
    {
      email,
      landingUrl
    },
    {
      baseURL: ENV.API_CES_AUTH
    }
  );

  return data;
};

export default {
  getPreLoginUserType,
  sendActivationCode,
  sendSelfServiceEmail,
  resendActivationEmail,
  oneTimeLoginUrl,
  validateUserBadAuthAttempts,
  sendPasswordlessWhileError
};
