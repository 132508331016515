import i18n from "i18next";
import { mapObjIndexed } from "ramda";
import { useCallback } from "react";
import { initReactI18next } from "react-i18next";
import { Cookie } from "shared";
import { getLocale } from "utils/hooks/useLocale";

import de from "./translations/de.json";
import en from "./translations/en.json";
import es from "./translations/es.json";
import fr from "./translations/fr.json";
import ja from "./translations/ja.json";
import pt from "./translations/pt.json";

export type DefaultNS = keyof typeof resources.en.default;

export const defaultNS = "default";

const isDebug = Cookie.getCookie("debug");

const formatTranslation = (translations): typeof en => {
  if (isDebug) {
    return mapObjIndexed(translation => `👉${translation}`)(
      translations
    ) as typeof en;
  }

  return translations;
};

export const resources = {
  de: {
    default: formatTranslation(de)
  },
  en: {
    default: formatTranslation(en)
  },
  es: {
    default: formatTranslation(es)
  },
  fr: {
    default: formatTranslation(fr)
  },
  ja: {
    default: formatTranslation(ja)
  },
  pt: {
    default: formatTranslation(pt)
  }
} as const;

const { language } = getLocale();

export const i18Instance = i18n.createInstance();

i18Instance.use(initReactI18next).init({
  lng: language,
  ns: [defaultNS],
  defaultNS: defaultNS,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  },
  resources,
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["b", "span"]
  }
});

i18Instance.services.formatter?.add("lowercase", value => value.toLowerCase());

export const useTranslation = (): [
  {
    (key: DefaultNS, options?): string;
    (key: string, options?): string;
  }
] => {
  const t = useCallback((key: string, options?) => {
    if (!key) {
      return "";
    }

    const text = i18Instance.t(key, "", options);

    if (text) {
      return text;
    }

    const formattedKey = key
      .replace(/[^a-zA-Z0-9]/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-|-$/g, "")
      .substring(0, 80);

    return i18Instance.t(formattedKey, key, options);
  }, []);

  return [t];
};

export default i18Instance;
