var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DateRange as Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Clickable from "../../elements/Clickable";
import DateTimePicker from "../DateTimePicker";
import styles from "./index.module.scss";
var RANGE_COLORS = ["#3ec0ad"];
var RANGE_KEY = "selection";
var getDatePortion = function (date) {
    var offset = date.getTimezoneOffset();
    var adjusted = new Date(date.getTime() - offset * 60 * 1000);
    return adjusted.toISOString().split("T")[0];
};
var DateRangePicker = function (_a) {
    var _b = _a.onChange, onChange = _b === void 0 ? function () { return ({}); } : _b, _c = _a.onClear, onClear = _c === void 0 ? function () { return ({}); } : _c, value = _a.value, range = _a.range, _d = _a.type, type = _d === void 0 ? "calendar" : _d, _e = _a.i18n, i18n = _e === void 0 ? {
        t: function (text) {
            return text;
        }
    } : _e;
    var t = i18n.t;
    var defaultDate = useMemo(function () { return ({
        startDate: new Date(),
        endDate: new Date()
    }); }, []);
    var initial = useMemo(function () {
        var _a = __assign({}, (range || defaultDate)), startDate = _a.startDate, endDate = _a.endDate;
        var today = new Date();
        if (+today > +endDate)
            return { startDate: endDate, endDate: endDate };
        if (+today < +startDate)
            return { startDate: startDate, endDate: startDate };
        return { startDate: today, endDate: today };
    }, [defaultDate, range]);
    var validateDate = useCallback(function (_a, isCallback) {
        var startDate = _a.startDate, endDate = _a.endDate;
        var startDatePortion = getDatePortion(startDate);
        var endDatePortion = getDatePortion(endDate);
        var startTimeAppend = "T00:00:00";
        var endTimeAppend = isCallback || type === "calendar" ? "T23:59:59" : "T00:00:00";
        return {
            startDate: new Date(startDatePortion + startTimeAppend),
            endDate: new Date(endDatePortion + endTimeAppend)
        };
    }, [type]);
    var _f = useState(validateDate(__assign({}, initial), false)), values = _f[0], setValues = _f[1];
    var limit = useMemo(function () {
        var _a = __assign({}, (range || defaultDate)), startDate = _a.startDate, endDate = _a.endDate;
        var min = +startDate > +endDate
            ? new Date(endDate.getTime())
            : new Date(startDate.getTime());
        var max = +startDate < +endDate
            ? new Date(endDate.getTime())
            : new Date(startDate.getTime());
        return validateDate({
            startDate: min,
            endDate: max
        }, false);
    }, [range, defaultDate, validateDate]);
    var handleSelectCalendar = useCallback(function (_a) {
        var selection = _a.selection;
        setValues(validateDate(selection, false));
        onChange(validateDate(selection, true));
    }, [onChange, validateDate]);
    var handleSelectInput = useCallback(function (type, value) {
        var _a;
        var updatedValues = __assign(__assign({}, values), (_a = {}, _a[type] = new Date(value), _a));
        setValues(validateDate(updatedValues, false));
        onChange(validateDate(updatedValues, true));
    }, [validateDate, values, onChange]);
    var handleClear = useCallback(function () {
        setValues(validateDate(initial, false));
        onChange(undefined);
        onClear();
    }, [initial, onChange, onClear, validateDate]);
    useEffect(function () {
        if (!value)
            return;
        setValues(value);
    }, [value]);
    return (_jsxs(_Fragment, { children: [type === "input" && (_jsxs("div", __assign({ className: styles.dateInputPicker }, { children: [_jsx(DateTimePicker, { value: values === null || values === void 0 ? void 0 : values.startDate.toString(), onSelect: function (value) { return handleSelectInput("startDate", value); }, label: t("start-date"), time: false, min: limit.startDate.toString(), max: limit.endDate.toString() }), _jsx(DateTimePicker, { value: values === null || values === void 0 ? void 0 : values.endDate.toString(), onSelect: function (value) { return handleSelectInput("endDate", value); }, label: t("end-date"), time: false, min: limit.startDate.toString(), max: limit.endDate.toString() })] }))), type === "calendar" && (_jsx(Calendar, { editableDateInputs: true, onChange: handleSelectCalendar, retainEndDateOnFirstSelection: true, moveRangeOnFirstSelection: false, ranges: [
                    __assign(__assign({}, values), { key: RANGE_KEY })
                ], shownDate: range === null || range === void 0 ? void 0 : range.endDate, rangeColors: RANGE_COLORS, minDate: limit.startDate, maxDate: limit.endDate })), _jsx(Clickable, __assign({ className: styles.clearSelection, onClick: handleClear }, { children: t("clear-selection") }))] }));
};
export default DateRangePicker;
