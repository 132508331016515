var downloadCSV = function (csv) {
    var _a;
    var today = new Date();
    var link = document.createElement("a");
    var decodedBinary = atob(csv.data);
    var decodedString = decodeURIComponent(escape(decodedBinary));
    link.href = window.URL.createObjectURL(new Blob([decodedString], { type: "text/csv;charset=utf-8;" }));
    var filename = csv.isIncludeDate
        ? "".concat(csv.name, "_").concat(today.toISOString(), ".csv")
        : "".concat(csv.name, ".csv");
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    (_a = link.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(link);
};
export default {
    downloadCSV: downloadCSV
};
