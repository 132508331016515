var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Children, isValidElement } from "react";
import styles from "./index.module.scss";
var Breadcrumbs = function (_a) {
    var children = _a.children;
    return (_jsx("div", __assign({ className: styles.breadcrumbs }, { children: Children.map(children, function (child, index) {
            var _a;
            var hasNestedChildren = isValidElement(child) && !!((_a = child.props.children) === null || _a === void 0 ? void 0 : _a.length);
            return (_jsxs(_Fragment, { children: [!!index && hasNestedChildren && (_jsx("div", __assign({ className: styles.divider }, { children: "/" }))), _jsx("div", __assign({ className: styles.crumb }, { children: child }))] }));
        }) })));
};
export default Breadcrumbs;
