var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout, enums } from "@cheetahdigital/console-kit-cx";
import { useCallback } from "react";
import { Modal, Headline, Buttons, Button } from "../..";
var ConfirmationModal = function (_a) {
    var title = _a.title, description = _a.description, content = _a.content, trigger = _a.trigger, onCancel = _a.onCancel, onOk = _a.onOk, okButtonLabel = _a.okButtonLabel, _b = _a.isActive, isActive = _b === void 0 ? true : _b, _c = _a.i18n, i18n = _c === void 0 ? {
        t: function (text) {
            return text;
        }
    } : _c;
    var t = i18n.t;
    var handleYes = useCallback(function () {
        onOk();
    }, [onOk]);
    return (_jsx(Modal, __assign({ isActive: isActive, onClose: onCancel, trigger: trigger }, { children: _jsxs(Layout, { children: [_jsx(Layout.Row, { children: _jsx(Headline, __assign({ size: "huge", isUppercase: false, isGapless: true }, { children: title })) }), _jsx(Layout.Row, { children: _jsx(Layout.Column, { children: description }) }), content && (_jsx(Layout.Row, { children: _jsx(Layout.Column, { children: _jsx("b", { children: content }) }) })), _jsx(Layout.Row, { children: _jsx(Layout.Column, __assign({ textAlign: enums.textAlignments.RIGHT }, { children: _jsxs(Buttons, { children: [_jsx(Button, __assign({ isSolid: false, onClick: onCancel }, { children: t("cancel") })), _jsx(Button, __assign({ isSolid: true, onClick: handleYes }, { children: okButtonLabel ? okButtonLabel : t("yes") }))] }) })) })] }) })));
};
export default ConfirmationModal;
