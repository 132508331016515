import { AxiosError } from "axios";
import { RequestErrorResponse } from "components/types";
import {
  Dispatch,
  SetStateAction,
  createContext,
  FC,
  useState,
  useContext,
  useCallback
} from "react";
import { MessageProp } from "shared/dist/components/Message";

import Message from ".";

export const MessageContext = createContext<{
  message?: MessageProp;
  setMessage: Dispatch<SetStateAction<MessageProp | undefined>>;
  closeMessage: () => void;
  setErrorMessage: (err) => void;
}>({
  message: undefined,
  setMessage: () => ({}),
  closeMessage: () => ({}),
  setErrorMessage: () => ({})
});

const MessageProvider: FC = ({ children }) => {
  const [message, setMessage] = useState<MessageProp | undefined>();

  const handleCloseMessage = useCallback(() => {
    setMessage(undefined);
  }, []);

  const setErrorMessage = useCallback(err => {
    const error = err as AxiosError<RequestErrorResponse>;
    const errorMessage = error?.response?.data?.errorMessage;
    const status = error?.response?.data?.status;

    if (errorMessage) {
      setMessage({
        type: status === "Warning" ? "warning" : "error",
        description: errorMessage
      });
      return;
    }

    setMessage({
      type: "error",
      description:
        "There is a technical error. Please try again or contact your administrator."
    });
  }, []);

  const context = {
    message,
    setMessage,
    closeMessage: handleCloseMessage,
    setErrorMessage
  };

  return (
    <MessageContext.Provider value={context}>
      {children}
      <Message />
    </MessageContext.Provider>
  );
};

export const useMessageContext = (): {
  message?: MessageProp;
  setMessage: React.Dispatch<React.SetStateAction<MessageProp | undefined>>;
  closeMessage: () => void;
  setErrorMessage: (err) => void;
} => {
  return useContext(MessageContext);
};

export default MessageProvider;
